import React, { Fragment, useState, useEffect, useRef } from "react";
import NutritionCareService from './NutritionCareService';
import { CounselingPanelTab } from "../HealthJourney/Counseling/List/Counseling";
import { PlannerVideoPlayer } from '../Common/VideoPlayer';
import GenericModal from '../Modals/GenericModal';
import useModal from "../../hooks/useModal";
import useStyle from "../../hooks/useStyle";
import Cs from "../../services/CommonService";
import { LocaleLabel, OptionsObjList } from "../Common/FormInput";
import { PillDsList } from "../Common/NotificationPill";
import CommentsModal from '../Modals/CommentsModal';
import { FontAwesomeIcon2 } from "../Common/ImageIcons";
import CounselingService from "../HealthJourney/Counseling/CounselingService";
import { CountryCodes } from "../../constants/CountryCodes";
import CheckAccess from "../Roles/CheckAccess";
import { DivRichTextView } from "../Common/RichTextView";
import { useCurrentUserHook } from "../Authentication/useUserHook";
import { useReadStatus } from '../ReadStatus/useReadStatusHook';
import { Spinner } from "../Common/Spinner";
import HealthCategoryService from "../HealthJourney/HealthCategoryService";
import { Locales } from "../../constants/DataSourceList";
import DataSourceService from "../../services/DataSourceService";
import { usePageLocale } from '../../hooks/useLocale';
import { ClientSearchEle } from "../../hooks/useSearch";
import Tabs, { TabPane } from "rc-tabs";

const NutritionCareNotification = (props) => {
  useStyle('/css/tile_components.css', true);

  const {currentUser, isKcorpOrg} = useCurrentUserHook();
  let { current: scope } = useRef({ requestParams: null, selectedItem: null, waLocale: currentUser.current_locale});

  const { labelLocale } = usePageLocale(currentUser.current_locale, 'journeyListPage');

  //const [nutritionCareList, setNutritionCareList] = useState([])
  //const [planners, setPlanners] = useState({});
  const [plannerList, setPlannerList] = useState([])
  const [notifications, setNotifications] = useState([])
  //const [formAlerts, setFormAlerts] = useState([])
  const [loading, setLoading] = useState(true)
  const [index, setIndex] = useState(null)
  const [isToggleOpen, setToggle] = useState(false)
  const [formFields, setFormFields] = useState([])
  const [currentpage,setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState({})
  const [isWaShared, setIsWaShared] = useState(props.isWaShared || false)
  const [dsTabs, setDsTabs] = useState([])

  const { isOpen: isVideoPlayerOpen, toggleModal: toggleVideoPlayer } = useModal()
  const { isOpen: isCommentOpen, toggleModal: toggleCommentModal } = useModal()

  const isWhatsappShareEnabled = CheckAccess.checkOrgLevelAccess(currentUser, 'whatsapp_share')

  const {updateReadStatus:updateReadCount} = useReadStatus();
  
  const setActiveIndex = (idx, is_item_viewed, notification_id, notification_type) => {
    const toggleIdx = (idx === index ? null : idx);
    if(toggleIdx!=null)getPlanners(idx);
    setIndex(toggleIdx);
    setToggle(toggleIdx?true:false);
    !is_item_viewed && updateReadStatus(notification_id, notification_type, idx);
  }

  useEffect(() => {
    if(props.counselingIds?.length > 0){
      scope.requestParams = {
        'counseling_ids':props.counselingIds,
      }
      getCounselingList();
    }else{
      scope.requestParams = {
        'locale_sid':props.localeSid,
        'page':currentpage,
        'per_page':10,
        'item_type': props.itemType,
        'item_uuid': props.itemUuid,
        'journey_profile_id':props.journeyProfileId,
        'parent_notification_id':props.parentNotificationId,
        'notification_id':props.notificationId
      }

      getNotificationList();

      scope.userLocales = CheckAccess.filterUserLocale(Locales, currentUser);
    }

    getDataSource();
  }, [])

  const getNotificationList = () => { 
    NutritionCareService.notifications(scope.requestParams).then((res)=>{
      if(res.status === 200){
        setPagemeta(res.data.meta)
        setPlannerList(res.data.planner_list || [])
        setNotifications(res.data.notifications || [])
        //setFormAlerts(res.data.form_alerts || [])
        setFormFields(Cs.formFieldListToObj(res.data.form_fields))
        setLoading(false)
      }
    })
  }

  const getCounselingList = () => { 
    CounselingService.notifications(scope.requestParams).then((res)=>{
      if(res.status === 200){
        setPlannerList(res.data.planner_list || [])
        setNotifications(res.data.counselings || [])
        setFormFields(Cs.formFieldListToObj(res.data.form_fields))
        setLoading(false)
      }
    })
  }

  const getDataSource = async () =>{
    setLoading(true);
    const data = await DataSourceService.getDataSource({
      'data_source_params':{
        'data_source_id':[4757],
        'locale':currentUser.current_locale
      }
    })

    if(data){
      const tabs = data.video_playlist_tabs?.options || [];
      tabs.unshift({'label':'All', 'sid':'all'});
      setDsTabs(tabs);
      setLoading(false);
    }
  }

  const updateReadStatus = (notification_id, notification_type, idx) =>{
    if(notification_id){
      NutritionCareService.updateEventDrivenNotification({
        id:notification_id,
        is_item_viewed:true
      }).then(({status})=>{
        if(status === 204){
          if(notification_type === 'planner'){
            plannerList[idx].is_item_viewed = true;
            setPlannerList([...plannerList]);
          }else if(notification_type === 'counseling'){
            notifications[idx].is_item_viewed = true;
            setNotifications([...notifications]);
          }else if(notification_type === 'nutrition_care'){
            notifications[idx].is_item_viewed = true;
            setNotifications([...notifications]);
          }
        }
      })

      if(!notifications[idx].views){
        updateReadCount(idx, {'item_id':notifications[idx].id, 'item_type':notification_type})
      }
    }
  }

  const getPlanners = (idx) => { 
    const n = notifications[idx]
    if(n.item_type && n.item_uuid){
      //here all planners are loaded for all notification.
      //load only planner for selected notification 
      setLoading(true);
      NutritionCareService.planners({
        'item_type':n.item_type,
        'item_uuid':n.item_uuid
      }).then((res)=>{
        if(res.status === 200){
          setPlannerList(res.data || []);
          setLoading(false);
        }
      })
    }
  }

  const openVideo = (planners, step_planners, planner_type, current_index) =>{
    scope.selectedItem = {startIndex:current_index}
    if(planner_type === 'step_planner'){
      scope.selectedItem.stepPlannerList = Object.assign([], step_planners);
     /* const params = new URL(planner.data.url).searchParams
      planner.data.video_id = params.get('v')
      const startSec = Cs.getSeconds(planner.data.start_time)
      const endSec = Cs.getSeconds(planner.data.end_time)
      selectedItem.data.video_url = `${planner.data.video_url}?start=${startSec}&end=${endSec}`*/
    }else{
      scope.selectedItem.plannerList = Object.assign([], planners);
    }
    toggleVideoPlayer()
  }

  const addComment = (e, item) => {
    e.stopPropagation()
    scope.selectedItem = {
      id: item.notification_id,
    }
    toggleCommentModal()
  }

  /*const suggestedVideos = (videos_suggested=[]) => videos_suggested.map((v, i) => {
    if(v.data?.reference_url){
      const params = new URL(v.data.reference_url).searchParams
      v.data.video_url = `${env.youtube_url}${params.get('v')}`
    }
    return (
      <a key={i} onClick={()=>updateReadStatus(v.notification_id)} className="font-18 p-10" href={planners[v.planner_fk_id]?.data?.reference_url} target="_blank">
        {planners[v.planner_fk_id]?.data?.name}
      </a>
    )
  })*/

  /*
<FontAwesomeIcon2 parentCss="p-t-6 pull-right bg-lgrey" 
                onSelect={e=>addComment(e, item)}
                iconCss="fas fa-comment-dots white"/>

  const CounselingPanels = ({item, i}) => {
    return(
      <div onClick={()=>updateReadStatus(item.notification_id, 'counseling', i)} className="col-xs-12 col-md-6" key={i}>
         <div className="bg-white brd-10 card-shadow m-b-15" key={i} onClick={()=>{!item.is_item_viewed && updateReadStatus(item.notification_id, 'counseling', i)}}>
            <div className="card-title card-title-gray flex p-b-15 p20 coldir">
              <h4 className="fw700 f18 card-heading flex coldir text-left">
                {item.data_source?.stage?.label}
                {item.is_item_viewed && <span className="fas fa-check-circle font-14 pull-right"/>}
              </h4>
            </div>
            <div className="card-content p20">
              <CounselingPanelTab data={item.data} key={i}/>
            </div>
        </div>
      </div>
    )
  }*/

  /*const formAlertListView =  formAlerts.map((item, i) => (
    <div className="p-5 bg-ltint" key={i}>
      <p className="font-16">
        {i+1}) {item.message}
      </p>
    </div>
  ))*/

  if(isCommentOpen){
    return <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={scope.selectedItem} type="notification"/>
  }

  const sendToWhatsApp = () =>{
    const f = props.countryCode?CountryCodes[props.countryCode.toLowerCase()]:null;
    if(f?.[2]){
      const u = window.encodeURI(`https://${window.location.hostname}/public/notifications/${props.journeyProfileId}/${scope.waLocale}`);
      window.open(`https://wa.me/${f?.[2]}${props.mobileNo}?text=${u}`);
      updateWhatsAppShared();
    }
  }

  const toggleFav = (i, item)=>{
    NutritionCareService.updateEventDrivenNotification({
      id: item.notification_id,
      is_favourite: !item.is_favourite
    }).then(({status})=>{
      notifications[i].is_favourite = !item.is_favourite;
      setNotifications([...notifications]);
    })
  }

  const updateWhatsAppShared = async () =>{
    const {status} = await HealthCategoryService.update({
      'is_json_update':true,
      'id':props.journeyProfileId,
      'key':'is_wa_shared',
      'value':1
    })
    setIsWaShared(true);
  }

  const notificationProps = {
    notifications, 
    isToggleOpen,
    plannerList,
    index, 
    setActiveIndex,
    currentUser,
    openVideo,
    formFields,
    updateReadStatus,
    toggleFav,
    isVideoPlayerPopupOpen:isVideoPlayerOpen,
    journeyProfileId:props.journeyProfileId
  }

  return (
    <Fragment>
      <div id="ct">
        <div className="clearfix">
          {loading ? 
            <Spinner/>
            :
            <>
              <div className="flex justspacebetween modal-title p-b-10">
                <h4 className="f18 text-white fw500 p12-10-15">
                  {labelLocale(25)}
                </h4>
              </div>
              <div className="m-t-10 p-l-10 p-r-10 vh-80 scroll-y">
                {(isWhatsappShareEnabled && props.mobileNo) ?
                  <div className="flex aligncenter justspacebetween card-shadow p-t-5 p-b-5 m-b-10 pointer">
                    <div className="flex gap-10 aligncenter" onClick={()=>sendToWhatsApp()}>
                      <FontAwesomeIcon2 
                        parentCss={"font-20 m-l-15 p-t-6 pull-right " + (isWaShared ? 'bg-green' : 'bg-lgrey')} 
                        iconCss="white font-18 fab fa-whatsapp"/>
                      <span className="fw700 f15">{isWaShared ? labelLocale(27) : labelLocale(26)}</span>
                    </div>
                    <select className="form-control input-sm fw500 w-200"
                      onChange={(e)=> scope.waLocale = e.target.value} 
                      placeholder="Status" defaultValue={scope.waLocale}>
                        <OptionsObjList list={scope.userLocales} idKey='sid' labelKey='label'/>
                    </select>
                  </div>
                  :null
                }

                <div className="row bg-white">
                  {isKcorpOrg() 
                    ? <Notifications {...notificationProps} tabSid="all"/> 
                    : <Tabs moreIcon="+">
                        {dsTabs.map((tab, k) => 
                          <TabPane tab={tab.label} key={tab.sid}>
                            <Notifications {...notificationProps} tabSid={tab.sid}/>
                          </TabPane>
                        )}
                      </Tabs>
                  }
                </div>

              </div>
              {/*<LoadMoreBtn currentPage={currentpage}
                totalItems={pagemeta.total}
                itemsPerPage={pagemeta.per_page}
                onPageChange={setCurrentpage}/>*/}
            </>
          }
        </div>
        {isVideoPlayerOpen && 
          <GenericModal 
            component={PlannerVideoPlayer}
            isOpen={isVideoPlayerOpen} 
            toggleModal={toggleVideoPlayer}
            title="Video Player"
            plannerList={scope.selectedItem.plannerList}
            stepPlannerList={scope.selectedItem.stepPlannerList} 
            startIndex={scope.selectedItem.startIndex}
            openInNewTab={true}/>
        }
      </div>
    </Fragment>
  )

}

const Notifications = (props) => props.notifications.map((n, i) => {
  if(props.tabSid === 'all' || n.data_source?.tab?.sid === props.tabSid){
    if (n.counseling_form_id) {
      const formFields = props.formFields[n.counseling_form_id];
      return <CounselingMotherInfoList item={n} key={i} i={i} formFields={formFields} {...props}/>;
    }
   
    return <NutritionCareCardView item={n} key={i} i={i} {...props}/>;
  }
  
  return null;
})
//notifications=[], currentUser, index, setIndex, isToggleOpen, plannerList

const CounselingMotherInfoList = (props) => {
    const {item, i, index, plannerList, setActiveIndex, isToggleOpen, currentUser, updateReadStatus, isVideoPlayerPopupOpen, formFields, toggleFav} = props;
    const isOpen = i === index;
    const [activePlanner, setActivePlanner] = useState();
    const ds = item.data_source || {};

    useEffect(()=>{
      if(isOpen){
        try{
          let defaultPlanner;
          if(isOpen && item.data?.planners_array?.length > 0){
            const planner = item.data?.planners_array[0];
            defaultPlanner = plannerList.find(p => p.id === planner.planner_fk_id && (p.notification_data?p.notification_data.counseling_id === item.id:true));
            if(defaultPlanner.planner_steps.length > 0){
              openVideo([defaultPlanner], defaultPlanner?.planner_steps, 'step_planner', 0);
            }else{
              openVideo([defaultPlanner], defaultPlanner?.planner_steps, null, 0);
            }
            /*TODO-remove after counseling id added to notification data*/
            /*p.notification_data will not be in counselings with params counseling_ids*/
            /*if(defaultPlanner.length > 1){
              defaultPlanner = defaultPlanner.find((p)=> p.notification_data.counseling_id === item.id)
            }

            if(defaultPlanner.length === 1){
              defaultPlanner = defaultPlanner[0]
            }*/
          } 
        }catch(e){
          console.error(e.message);
        }
      }
    }, [isOpen])

    const openVideo = (planners, step_planners, planner_type, current_index) =>{
      let selectedItem = {'startIndex': current_index};
      if(planner_type === 'step_planner'){
        selectedItem.stepPlannerList = Object.assign([], step_planners);
        selectedItem.autoPlay = true;
      }else{
        selectedItem.plannerList = Object.assign([], planners);
      }
      setActivePlanner({...selectedItem});
    }

    if(isOpen === false && isToggleOpen)
      return null;

    return(
      <div className="col-xs-12" key={i}>
        <div className="card-shadow m-b-15">
          <div className="card-title flex p-t-10 p-b-10 p-l-10 p-r-10 coldir rgap-5"
            onClick={()=>setActiveIndex(i, item.is_item_viewed, item.notification_id, 'counseling')}>
            <h4 className={`${isOpen?'fw400':'fw700'} f15 card-heading flex coldir text-left`}>
              {ds.step ?
                <LocaleLabel dataSource={ds.step} currentLocale={currentUser.current_locale}/> 
                :
                <LocaleLabel dataSource={ds.stage} currentLocale={currentUser.current_locale}/> 
              }
            </h4>
            <div className="f14 color-gray2-dark fw500">
              {item.is_item_viewed && <span className="fas fa-check-circle m-r-10 green"/>}
              <LocaleLabel dataSource={ds.stage} currentLocale={currentUser.current_locale}/> 
              <span className="f12 text-muted m-l-10">{item.record_id}</span>
              <span className="m-l-15">{Cs.formatUpdateDate(item.notification_created_at)}</span>
              {item.views ? <span className="f12 text-muted m-l-15">Views: {item.views} </span> : null}
              {CheckAccess.isDevUser(currentUser.email) ?
                <>
                  {item.form_rule_id ? <span className="f12 text-muted m-l-15">FR : {item.form_rule_id}</span> : null}
                </>
                : null
              }

              <FontAwesomeIcon2 parentCss="m-l-15 p-t-6 pull-right bg-lgrey font-20" 
                iconCss={`white font-18 fas fa-chevron-${isOpen?'down':'right'}`}/>

              <FontAwesomeIcon2 parentCss={`font-20 m-l-15 p-t-6 pull-right ${item.is_favourite ? 'bg-green' : 'bg-lgrey'}`} 
                onSelect={()=>toggleFav(i, item)}
                iconCss={`font-18 white ${item.is_favourite ? 'fas fa-heart' : 'far fa-heart'}`}/>
            </div>
          </div>
          {isOpen &&
            <div className={`card-content p-l-10 p-r-10 ${isOpen ? 'ani-zoom' :''}`}>
              {activePlanner ?
                <PlannerVideoPlayer 
                  playInline={true}
                  {...activePlanner}/>
                :null
              }
              <div className="b-tab-table active">
                <div className="card-content p-t-20 p-b-20">
                  <CounselingPanelTab key={i}
                    data={item.data} 
                    formFields={formFields[item.counseling_form_id]} 
                    currentLocale={currentUser.current_locale} />
                </div>
                {plannerList?.length > 0 &&
                  <PlannerListView 
                    videos={item.data?.planners_array} 
                    parentCss="col-xs-12"
                    plannerList={plannerList} 
                    openVideo={openVideo} 
                    updateReadStatus={updateReadStatus}/>
                }
              </div>
            </div>
          }
        </div>
      </div>
    )
  }

  const PlannerStepListView = ({plannerSteps=[], planner, openVideo, updateReadStatus, plannerIdx}) =>  plannerSteps.map((item, i) => {
    return(
      <div onClick={()=>{openVideo(null, plannerSteps, 'step_planner', i);!item.is_item_viewed && updateReadStatus(planner.notification_id, 'step_planner', plannerIdx);}} 
        className="br-btm-grey miw-200" key={i}>
        <div className="flex coldir gap-10 aligncenter bold-500 op-09 black-light-1 p-5">
          <span className="fab fa-youtube f40 yt-red"/> 
          <div>
            {item.data.start_time} - {item.data.description}
            {item.is_item_viewed && <span className="fas fa-check-circle font-14 m-l-15"/>}
          </div>
        </div>
      </div>
    )
  })

  const PlannerListView = ({videos=[], parentCss, plannerList, openVideo, updateReadStatus}) => videos.map((v, i) => {
    /*i===0 First video already loaded*/
    if(plannerList.length==0){
      return null;
    }
    
    let planner = plannerList.find(p => p.id === v.planner_fk_id);
    if(!planner){
     return null;
    }else if(planner.data.reference_url){
      const params = new URL(planner.data.reference_url).searchParams;
      planner.data.img_url = `https://img.youtube.com/vi/${params.get('v')}/mqdefault.jpg`;
    }

    return(
      <div className="miw-200 m-r-5" key={i}>
        <div className="bg-white brd-10 card-shadow m-b-15">
          <div className="card-title card-title-gray white flex p-b-15 p10 coldir">
            <h4 className="fw700 f14 card-heading flex coldir text-left">
              {planner.data.name}  
            </h4>
            {planner.is_item_viewed && 
              <div className="f14 m-t-10 fw500">
                <span className="fas fa-check-circle font-14 pull-right"/>
              </div>
            }
          </div>
          <div className="card-content flex p5 pointer scroll-auto">
            {planner.planner_steps?.length > 0 ? 
              <PlannerStepListView key={i} 
                plannerSteps={planner.planner_steps} 
                planner={planner}
                openVideo={openVideo} 
                updateReadStatus={updateReadStatus}
                plannerIdx={i}/>
              :
              <div className="border-dashed-sm play-btn p5 mih-80"
                onClick={()=>{openVideo([planner], null, 'planner', 0);!planner.is_item_viewed && updateReadStatus(planner.notification_id, 'planner', i);}}>
                <img src={planner.data.img_url} className="img-thumbnail img-rounded img-responsive center-block"/>
              </div>
            }
          </div>
        </div>
      </div>
    )
  })

  const NutritionCareAlerts = ({alerts=[]}) => alerts ? alerts.map((alert, i) => (
    <Fragment key={i}>
      <div>{alert.data.title}</div>
      <DivRichTextView className="form-details m-t-10" html={alert.data.details}></DivRichTextView>
    </Fragment>
  )):null

  const NutritionCarePlanners = ({alerts=[], plannerList, openVideo, updateReadStatus, setActivePlanner}) => alerts ? alerts.map((alert, i)=>{
    return(
      <Fragment key={i}>
        {alert.data?.videos_suggested_array?.length > 0 &&
          <div className="flex scroll-auto">
            <PlannerListView 
              videos={alert.data?.videos_suggested_array} 
              parentCss="col-xs-12"
              plannerList={plannerList} 
              openVideo={openVideo} 
              updateReadStatus={updateReadStatus}/>
          </div>
        }
      </Fragment>
    )
  }):null

  const NutritionCareCardView = (props) => {
    const {item, i, index, plannerList, setActiveIndex, isToggleOpen, currentUser, updateReadStatus, isVideoPlayerPopupOpen, journeyProfileId, toggleFav} = props;
    const isOpen = (i === index);
    const [activePlanner, setActivePlanner] = useState();
    const {current:scope} = useRef({});
    scope.nca = item.nutrition_care_alerts?.[0];

    useEffect(()=>{
      try{
        /*Loading first video in first alert as default planner.*/
        if(isOpen){
          const planner = scope.nca.data.videos_suggested_array[0];
          const defaultPlanner = plannerList.find(p => p.id === planner.planner_fk_id && p.notification_data.nutrition_care_alert_id === scope.nca.id);   
          openVideo([defaultPlanner], defaultPlanner?.planner_steps, null, 0);
        }
      }catch(e){
        console.error(e.message);
      }
    }, [isOpen])

    const openVideo = (planners, step_planners, planner_type, current_index) =>{
      let selectedItem = {startIndex:current_index};
      if(planner_type === 'step_planner'){
        selectedItem.stepPlannerList = Object.assign([], step_planners);
        selectedItem.autoPlay = true;
      }else{
        selectedItem.plannerList = Object.assign([], planners);
      }
      setActivePlanner({...selectedItem});
    }

    if(isOpen === false && isToggleOpen) return null;

    return(
      <div className="col-xs-12" key={i}>
        <div className="card-shadow m-b-15">
          <div className="card-title flex p-t-10 p-b-10 p-l-10 p-r-10 coldir rgap-5"
            onClick={()=>setActiveIndex(i, item.is_item_viewed, item.notification_id, 'nutrition_care')}>
            <h4 className={`${isOpen?'fw400':'fw700'} f15 card-heading flex coldir text-left`}>
              {scope.nca?.data?.title || item.data.title}
            </h4>
            <div className="f14  color-gray2-dark fw500">
              {item.is_item_viewed &&
                <span className="fas fa-check-circle m-r-10 green"/>
              }
              {scope.nca && <PillDsList list={scope.nca.data_source.mcj_forms || []} dataKey="label"/>}
              <span className="f12 text-muted m-r-5">{item.record_id}</span>
              {Cs.formatUpdateDate(item.notification_created_at)}
              {item.views ? <span className="f12 text-muted m-l-15">Views: {item.views} </span> : null}
              {CheckAccess.isDevUser(currentUser.email) ?
                <>
                  {item.form_rule_id ? <span className="f12 text-muted m-l-15">FR - {item.form_rule_id}</span> : null}
                </>
                : null
              }
              <FontAwesomeIcon2 parentCss="m-l-15 p-t-6 pull-right bg-lgrey font-20"
               iconCss={`white font-18 fas fa-chevron-${isOpen?'down':'right'}`}/>
               
              <FontAwesomeIcon2 parentCss={`font-20 m-l-15 p-t-6 pull-right ${item.is_favourite ? 'bg-green' : 'bg-lgrey'}`} 
                onSelect={()=>toggleFav(i, item)}
                iconCss={`font-18 white ${item.is_favourite ? 'fas fa-heart' : 'far fa-heart'}`}/>
            </div>
          </div>
          {isOpen &&
            <div className={`card-content p-l-10 p-r-10 ${isOpen ? 'ani-zoom' :''}`}>
              {activePlanner ?
                <PlannerVideoPlayer 
                  playInline={true}
                  plannerList={activePlanner.plannerList}
                  stepPlannerList={activePlanner.stepPlannerList} 
                  startIndex={activePlanner.startIndex}
                  autoPlay={activePlanner.autoplay}/>
                :null
              }
              <div className="b-tab-table active">
                {item.nutrition_care_alerts?.length > 0 &&
                  <NutritionCarePlanners 
                    alerts={item.nutrition_care_alerts}
                    plannerList={plannerList} 
                    openVideo={openVideo} 
                    updateReadStatus={updateReadStatus}/>
                }
                <div className="p-10 br-grey m-t-10">            
                  <NutritionCareAlerts alerts={item.nutrition_care_alerts}/>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }

export default NutritionCareNotification;
