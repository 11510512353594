import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthenticationService from "../../services/AuthService";
import { CacheContext } from "../../contexts/Cache/CacheStateProvider";
import CheckAccess from '../Roles/CheckAccess';
import { EditLocaleIco } from '../Locale/LocaleIcon';
import useModal from "../../hooks/useModal";
import UserAccessComponents from "../Roles/UserAccessComponents";
import { LocalizeContext } from "../../contexts/Localize/LocalizeStateProvider";
import { HelpIcon } from "../Help/HelpSidePanel";
import { PushNotificationPopup } from "../PushNotification/PushNotificationPage";
import { AppDownload } from "../Common/AddToHomeScreen";
import { DataSources } from "../../constants/DataSourceList";
import { usePageLocale } from "../../hooks/useLocale";
import DataSourceService from "../../services/DataSourceService";
import { useCurrentUserHook } from "../Authentication/useUserHook";

const SideMenu = (props) => {
  const {currentUser, dispatch} = useCurrentUserHook();
  const {cacheState, cacheDispatch} = useContext(CacheContext);
  const {localeState:{sideMenuList, digitalContent, trainingMenu}, localeDispatch} = useContext(LocalizeContext);

  const sideNavEl = useRef(null);
  const sideNavMenuEl = useRef(null);
  const history = useHistory();
  const isDevAdmin = CheckAccess.isDevUser(currentUser.email);

  const [sideMenu, setSideMenu] = useState();
  const [loading, setLoading] = useState(true);
  const { isOpen: isPushSettingOpen, toggleModal:togglePushSetting } = useModal();
  const {labelLocale:topNavLocale} = usePageLocale(currentUser.current_locale, 'sideMenuTopNav');

  const orgLogo = currentUser?.current_organization?.data?.picture || 'images/logo/cuetree_Icon.png';

  useEffect(() =>{
    setSideMenu(sideMenuList?.options || {});
  }, [sideMenuList])

  const signout = (e) => {
    e.stopPropagation();
    e.preventDefault();
    AuthenticationService.signout().then(() => {
      cacheDispatch({
        type: "UPDATE",
        payload: {}
      })
      dispatch({ type: "LOGOUT" });
    })
  }

  useEffect(() => {
    getSideMenuDs();
    document.addEventListener("keydown", handleEscape);
    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("click", handleClick, true);
    }
  }, [currentUser.current_locale])

  const handleEscape = (e) => {
    if (e.keyCode === 27) props.setOpenSideNav(false);
  }

  /*const handleClick = (e) => {
    if (
      !currentUser.edit_locale && (e.target.id !== 'edit_locale')
      && sideNavEl.current 
      && (sideNavEl.current.contains(e.target) || sideNavMenuEl.current.contains(e.target))
    ) {
      props.setOpenSideNav(false);
    }
  }*/

  const handleClick = (e) => {
    if (
      (sideNavMenuEl.current && sideNavMenuEl.current.contains(e.target))
    ) {
      close();
    }
  }

  const close = (e) => {
    e && e.preventDefault();
    props.setOpenSideNav(false);
  }
    
  const checkAccess = (menuType) => CheckAccess.checkOrgAccess(currentUser, menuType);
  const checkTrainingAccess = (menuType) => CheckAccess.checkTrainingAccess(currentUser, menuType);

  const editLocale = () =>{
    currentUser.edit_locale = !currentUser.edit_locale
    dispatch({
      type: "UPDATE",
      payload: { user: currentUser },
    })
  }
  
  const routeOrgProfile = (e) =>{
    e.preventDefault()
    const userOrg = currentUser.organizations.find((o)=> o.created_by === currentUser.id)
    if(userOrg){
      history.push(`/profile/${userOrg.id}`)
    }
  }

  const getSideMenuDs = () =>{
    setLoading(true)
    DataSourceService.getDataSource({
      'data_source_params':{
        'data_source_id':[
          DataSources.sideMenuDsId, 
          DataSources.headerDsId, 
          DataSources.digitalContentMenu, 
          DataSources.confirmMenuDsId, 
          DataSources.sideMenuTopNav,
          4083, 
          4082,
          3886,
          3613,
          4603,
          4605
        ],
        'response_type':'object', 
        'group_by':'sid',
        'locale':currentUser.current_locale,
        'per_page' : 30
      }
    }).then((data)=>{
      if(data){
        localeDispatch({
          type: "UPDATE",
          payload: {
            'sideMenuList':(data.section_menu_nav_bar),
            'digitalContent':(data.menu_digital_content?.options || {}),
            'header':(data.text_header_items),
            'confirmMenu':(data.delete_confirm),
            'sideMenuTopNav':(data.side_menu_top_nav),
            'journeyStatus':Object.values(data.status_journey_case.options),
            'statusCloseReasons':Object.values(data.status_close_reason.options),
            'statusInactiveReasons':Object.values(data.status_inactive_reason.options),
            'languages':Object.values(data.languages.options),
            'cookieNotice':data.cookie_notice,
            'trainingMenu':data.training_menu,
          }
        })
        //setSideMenu(data.section_menu_nav_bar?.options || {})
        setLoading(false)
      }
    })
  }

  const onLocaleUpdate = (menu) =>{
    sideMenu[menu.sid] = menu
    setSideMenu({...sideMenu})
  }

  return (
    <div id="menu-main" ref={sideNavEl}
      className={`menu menu-box-left menu-box-detached round-medium ${props.openSideNav ? "menu-active" : ""} `}
      style={{ width: 260, opacity: 1, display: "block" }}>
      <div className="menu-icons pointer">
        <span onClick={()=>window.location.reload()}>
          <i className="fas fa-refresh color-blue2-dark" />
        </span>
        <span onClick={() => {props.toggleOrgModal();close();}}>
          <i className="fas fa-building color-green1-dark" />
        </span>
        <span>
          <i className="fas fa-sitemap color-orange-dark" />
        </span>
        <Link to="/user/profile" onClick={() => close()}>
          <i className="fa fa-cog color-blue2-dark" />
        </Link>
        <span className="close-menu" onClick={(e) => close(e)}>
          <i className="fa fa-times color-red2-dark " />
        </span>
        <div className="clear" />
      </div>

      <div className="menu-items">
        <div className="p-10 brb-lgrey">
          <div className="font-13 p-l-15 m-t-5 pointer black-light-1">
            <div className="m-b-15" data-tip="Language Menu" 
              onClick={() => {props.toggleLocaleModal();close();}}>
              <i className="fas fa-globe w-30 color-blue2-dark" />
              <span className="text-cap">
                {topNavLocale('1')} - {currentUser.current_locale}
              </span>
            </div>
            <AppDownload label={topNavLocale(2)}/>
            <div className="m-b-15" data-tip="Signed in as">
              <i className="fas fa-user-tag w-30 color-blue2-dark" />
              <span>{currentUser.name}</span>
            </div>
            <div className="m-b-15" data-tip="Orgnization Menu" 
              onClick={() => {props.toggleOrgModal();close();}}>
              <i className="fas fa-building w-30 color-blue2-dark" />
              <span>{currentUser?.current_organization?.data?.name}</span>
            </div>
            {isDevAdmin && 
              <span id="edit_locale" className="font-14" onClick={()=>editLocale()} data-tip="Switch Locale">
                <i className="fas fa-yin-yang w-30 color-blue2-dark"/>
                <span id="edit_locale">Set language</span>
              </span>
            }
          </div>
          <div className="text-center">
            <img className="org-logo" src={orgLogo}/>
          </div>
        </div>
      
        <div className="content m-b-10">
          {loading ?
            null
            :
            <div className="link-list link-list-1" ref={sideNavMenuEl}>
              {currentUser.current_subscription?.journeys 
                ? <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.dashboard} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-home color-blue2-dark" label="Home" sid="dashboard"
                  link="/health/table"/>
                : null
              }

              {checkAccess('review') &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.review} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="far fa-comment color-blue2-dark" 
                  label="Review" sid="review"
                  link="/health/comment_review/page"/>
              }

              {checkAccess('add_org_member_data') &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.add_org_member_data} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-notes-medical color-blue2-dark" label="Add Data for Members" 
                  sid="add_org_member_data" link="/data/entry/members"/>
              }
                
              {checkAccess('contacts') &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.contacts} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-users color-blue2-dark" label="Contacts" sid="contacts"
                  link="/schedules?filter=all"/>
              }

              {isDevAdmin &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.data_explorer} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-book color-blue2-dark" label="Contact Requests" 
                  sid="data_explorer" link="/list/contacts"/>
              }
              
              {checkAccess('facilities') &&  
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.facilities} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-hospital color-blue2-dark" label="Facilities" 
                  sid="facilities" 
                  link={`/health/list/facilities/${currentUser.current_organization_id}/${currentUser?.current_subscription?.id}`}/>
              }

              {isDevAdmin && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.organizations} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-hospital color-blue2-dark" label="Organizations" 
                  sid="organizations" link="/list/organizations"/>
              }

              {checkAccess('org_backups') && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.org_backups} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-database color-blue2-dark" label="Org Csv Backup" 
                  sid="org_backups" link={`/backup/${currentUser.current_organization_id}`}/>
              }

              {checkAccess('management_actions') &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.management_actions} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="far fa-comment color-blue2-dark" 
                  label="Management Action" sid="management_actions"
                  link={`/org/initiatives/${currentUser.current_organization_id}`}/>
              }

              {isDevAdmin && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.personal_users} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-user color-blue2-dark" label="Personal Users" 
                  sid="personal_users" link="/list/personal/users"/>
              }

              {isDevAdmin && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.subscriptions} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-user-shield color-blue2-dark" label="Subscriptions" 
                  sid="subscriptions" link="/list/subscriptions"/>
              }
              
              {checkAccess('members') && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.members} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-hospital color-blue2-dark" label="Organization Members" 
                  sid="members" 
                  link={`/${currentUser.current_organization.data.name}/members/${currentUser.current_organization_id}`}/>
              }

              {checkAccess('org_member_view') && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.org_member_view} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-user color-blue2-dark" 
                  sid="org_member_view" label="Project User Account List" 
                  link={`/${currentUser.current_organization.data.name}/view/members/${currentUser.current_organization_id}`}/>
              }
              
              {checkAccess('member_ppt_process_report') && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.member_ppt_process_report} 
                  onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-hospital color-blue2-dark" 
                  label="Org Member Activity Summery" 
                  sid="member_ppt_process_report" 
                  link="/members/mcj/activity"/>
              }

              {checkAccess('mentor_supervisor_report') && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.mentor_supervisor_report} 
                  onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-user color-blue2-dark" 
                  label="Coaching" 
                  sid="mentor_supervisor_report" 
                  link="/members/mcj/activity?tab=coaching"/>
              }

              {checkAccess('multi_org_reports') && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.multi_org_reports} 
                  onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-chart-bar color-blue2-dark" 
                  label="Master Project Dashboard" 
                  sid="multi_org_reports" 
                  link="/orgs/stats"/>
              }

              {(checkAccess('roles') || isDevAdmin) && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.roles} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-user color-blue2-dark" label="Roles" 
                  sid="roles" 
                  link={`/health/role/infant_journey/${currentUser.id}/${currentUser.current_organization_id}/${currentUser?.current_subscription?.id}`}/>
              }

              {(checkAccess('partners') || isDevAdmin) && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.partners} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-user color-blue2-dark" label="Partners" sid="partners"
                  link="/list/partners"/>
              }

              {checkAccess('guides') && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.guides} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-book color-blue2-dark" label="Guide" 
                  sid="guides" link="/health/planner"/>
              }

              {/*checkAccess('interventions')*/}

              {(isDevAdmin || checkAccess('interventions'))  &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.interventions} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-hospital color-blue2-dark" label="Intervention Protocols" 
                  sid="interventions" link="/nutrition_cares"/>
              }
              
              {(isDevAdmin || checkAccess('interventions'))  &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.intervention} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-hospital color-blue2-dark" label="IPA Manager" 
                  sid="intervention" link="/templates/nutrition_cares"/>
              }

              {/*checkAccess('calendar')*/}

              {checkTrainingAccess('org_trainings') &&  
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={trainingMenu.org_trainings} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-user color-blue2-dark" label="Training Program" 
                  sid="org_trainings" link="/list/training_programs"/>
              }
              
              {checkTrainingAccess('training_admin') &&  
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={trainingMenu.training_admin} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-user color-blue2-dark" label="Training Management" 
                  link={`/org/training_manager/${currentUser.current_organization_id}`}
                  sid="training_admin"/>
              }

              {(isDevAdmin || checkAccess('calendar')) &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.calendar} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-calendar color-blue2-dark" label="Calendar Manager" 
                  sid="calendar" link="/schedule/event"/>
              }
                
              {(isDevAdmin || checkAccess('insights')) &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.reports} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-book color-blue2-dark" label="Reports" 
                  sid="reports" link="/infant/chart/state"/>
              }
              
              {isDevAdmin &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.data_explorer} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-book color-blue2-dark" label="MCJ data explorer" 
                  sid="data_explorer" link="/infant/measurements"/>
              }

              {/*checkAccess('manage_counseling') && */}

              {(isDevAdmin || checkAccess('counseling'))&& 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.counseling} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-people-arrows color-blue2-dark" label="Counseling" 
                  sid="counseling" link="/infant/list/counseling"/>
              }

              {(isDevAdmin || checkAccess('counseling'))&& 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.counseling_template} 
                  onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-people-arrows color-blue2-dark" 
                  label="Counseling Templates" 
                  sid="counseling_template" 
                  link="/templates/counselings"/>
              }

              {(isDevAdmin || checkAccess('form_test_inputs'))&& 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.form_test_input_templates} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-book color-blue2-dark" 
                  label="Form Test Input Template" 
                  sid="form_test_inputs" 
                  link="/test/input/form/templates"/>
              }

              {(isDevAdmin || checkAccess('form_test_inputs'))&& 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.form_test_inputs} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-book color-blue2-dark" 
                  label="Form Test" 
                  sid="form_test_inputs" 
                  link="/test/input/forms"/>
              }

              {(isDevAdmin || checkAccess('form_test_input_reviews'))&& 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.form_test_input_reviews} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-book color-blue2-dark" 
                  label="Form Test Review" 
                  sid="form_test_input_reviews" 
                  link="/test/input/review"/>
              }

              {isDevAdmin &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.chat_messenger} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-comments color-blue2-dark" label="Chat Messenger" 
                  sid="chat_messenger" href="/" onSelect={props.toggleChatModal}/>
              }
                
              {checkAccess('community') &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.community} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-globe color-blue2-dark" label="Practice Community" 
                  sid="community" link="/forums"/>
              }
              
              {/*checkAccess('calendar')*/}

              {isDevAdmin &&  
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.field_resource_management} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-user color-blue2-dark" label="Resource Management" 
                  sid="field_resource_management" link="/resource/inventories"/>
              }
              
              {/*checkAccess('communications')*/}  

              {(isDevAdmin || checkAccess('communications')) && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.communications} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-newspaper color-blue2-dark" label="Digital Content" 
                  sid="communications" link="/digital_contents"/>
              }
              
              {checkAccess('what_new') && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={digitalContent.what_new} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-newspaper color-blue2-dark" label="What's New" 
                  sid="what_new" 
                  link={`/org/whatsnew/${digitalContent.what_new?.child_form}/${currentUser.current_organization_id}`}/>
              }

              {checkAccess('screening') && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.screening} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-newspaper color-blue2-dark" label="Screening Events" 
                  sid="screening" link="/screening_events"/>
              }
                
              {checkAccess('data_choices') && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.data_choices} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-database color-blue2-dark" label="Data Sources" 
                  sid="data_choices" link="/list/forms"/>
              }

              {checkAccess('form_locale_audit') && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.form_locale_audit} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-database color-blue2-dark" label="Form Locale Check" 
                  sid="form_locale_audit" link="/form/locale/suggestions"/>
              }

              {checkAccess('locale_labels') && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.locale_labels} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-database color-blue2-dark" label="Form Locale Check" 
                  sid="locale_labels" link="/language/labels"/>
              }
              
              {checkAccess('user_reg_request') &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.help} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-user color-blue2-dark" label="User Registrations" 
                  sid="user_reg_request" link="/user/request_list"/>
              }

              {checkAccess('lc_survey') &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.lc_survey} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-clipboard-check color-blue2-dark" label="User Registrations" 
                  sid="lc_survey" link={`/list/user/survey/${currentUser.current_organization_id}`}/>
              }

              {isDevAdmin &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.help} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="far fa-question-circle color-blue2-dark" label="Help" 
                  sid="help" link="/help"/>
              }

              <MenuIcon currentLocale={currentUser.current_locale} 
                menuItem={sideMenu.profile} onLocaleUpdate={onLocaleUpdate} 
                iconCss="fa fa-user color-blue2-dark" label="Profile" sid="profile"
                onSelect={routeOrgProfile}/>

              {checkTrainingAccess('user_trainings') &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={trainingMenu.user_trainings} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-book color-blue2-dark" label="Training for Users" 
                  sid="user_trainings" link="/user/training_programs"/>
              }

              {checkTrainingAccess('quiz_dashboard') &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={trainingMenu.quiz_dashboard} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-book color-blue2-dark" label="HCW Testing Dashboard" 
                  sid="quiz_dashboard" link="/quiz/reports"/>
              }

              {checkTrainingAccess('pre_workshop_quiz_dashboard') &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={trainingMenu.f2f_training_dashboard} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-book color-blue2-dark" label="Pre Workshop Assessment" 
                  sid="f2f_training_dashboard" link="/preworkshop/quiz/report"/>
              }

              {checkAccess('welcome_tour') &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.welcome_tour} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="far fa-images color-blue2-dark" label="Welcome Tour" 
                  sid="welcome_tour" link="/journey/tour"/>
              }
              
              {/*User Quiz*/}
              <MenuIcon currentLocale={currentUser.current_locale} 
                menuItem={trainingMenu.quiz} onLocaleUpdate={onLocaleUpdate} 
                iconCss="far fa-question-circle color-blue2-dark" label="Quiz" 
                sid="quiz" link="/list/quiz"/>

              {checkTrainingAccess('schedules') &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={trainingMenu.schedules} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-calendar color-blue2-dark" label="Training Calendar" 
                  sid="schedules" link={`/training_programs/schedules/${currentUser.current_organization_id}`}/>
              }
              
              {checkAccess('collections_management') &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.collections_management} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="far fa-images color-blue2-dark" label="Collections Management" 
                  link={`/org/collections/${currentUser.current_organization_id}`}
                  sid="collections_management"/>
              }

              {(isDevAdmin || checkAccess('offline')) && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.offline} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="far fa-question-circle color-blue2-dark" label="Offline" 
                  sid="offline" link="/offline" id="offline-nav"/>
              }

              {isDevAdmin && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.push} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-user-shield color-blue2-dark" 
                  label="Push Setting" sid="push_setting" link="/push/settings"/>
              }

              {checkAccess('feedback') &&
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.feedback} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="far fa-question-circle color-blue2-dark" label="Feedback" 
                  sid="feedback" link="/list/feedback"/>
              }

              {checkAccess('form_suggestion') && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  onLocaleUpdate={onLocaleUpdate} 
                  iconCss="far fa-question-circle color-blue2-dark" 
                  label="FormField Suggestions" 
                  sid="formfield_suggestion" 
                  link="/list/formfield/images" 
                  id="formfield_suggestion"/>
              }

              <MenuIcon currentLocale={currentUser.current_locale} 
                menuItem={sideMenu.sign_out} onLocaleUpdate={onLocaleUpdate} 
                iconCss="fa fa-times color-red2-dark" label="Sign Out" sid="sign_out"
                onSelect={signout}/>
            </div>
          }

          <PushNotificationPopup currentUser={currentUser}/>

          <div className="center-text m-t-10">
            {/*<a href="#"
              className="icon icon-xxs round-tiny shadow-large m-b-0 bg-facebook">
              <i className="fab fa-facebook-f" />
            </a>
            <a href="#"
              className="icon icon-xxs round-tiny shadow-large m-b-0 bg-twitter">
              <i className="fab fa-twitter" />
            </a>
            <a href="#"
              className="icon icon-xxs round-tiny shadow-large m-b-0 bg-instagram">
              <i className="fab fa-instagram" />
            </a>
            <a href="#"
              className="icon icon-xxs round-tiny shadow-large m-b-0 bg-linkedin">
              <i className="fab fa-linkedin-in" />
            </a>
            <a href="#"
              className="icon icon-xxs round-tiny shadow-large m-b-0 bg-whatsapp">
              <i className="fab fa-whatsapp" />
            </a>*/}
            <p className="m-t-10 font-10 op-50">
              <i className="fa fa-copyright" aria-hidden="true"></i>
              <span className="copyright-year">2023</span> IPIPAL Inc. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const MenuIcon = ({link, onSelect, iconCss, label, sid, onLocaleUpdate, menuItem, currentLocale}) => {
  
  const menuLocale = useMemo(() => {
    return {
      "label" : menuItem?.[currentLocale]?.label || menuItem?.label || label
    }
  }, [sid])

  if(link){
    return(
      <div className="flex justspacebetween aligncenter">
        <Link id={sid} to={link} className="w-82">
          <i className={iconCss} />
          <span>
            {menuLocale.label}
          </span>           
        </Link>
        <div className="">
          {menuItem ? 
            <EditLocaleIco localeType="data_source_data" 
              labelObj={menuItem} onLocaleUpdate={onLocaleUpdate}/> 
            : null
          }
          <HelpIcon recordIds={menuItem?.help_id} className="font-14 m-r-10"/>
          <i className="fa fa-angle-right r-ico"/>         
        </div>
      </div>
    )
  }else{
    return(
      <div className="flex justspacebetween aligncenter">
        <a id={sid} href="/" onClick={e=>onSelect(e)}  className="w-82">
          <i className={iconCss} />
          <span>{menuLocale.label}</span>
        </a>
        <div className="">
          {menuItem ? 
            <EditLocaleIco localeType="data_source_data"
              labelObj={menuItem} onLocaleUpdate={onLocaleUpdate}/> 
            : null
          }
          <HelpIcon recordIds={menuItem?.help_id} className="font-14 m-r-10"/>
          <i className="fa fa-angle-right r-ico"/>         
        </div>
      </div>
    )
  }
}

export default SideMenu;

/*
{checkAccess('invite_user') &&
              <Link id="nav-users" to={`/health/members/infant_journey/${currentUser.id}`}>
                <i className="fa fa-globe color-blue2-dark" />
                <span>Users</span>
                <i className="fa fa-angle-right" />
              </Link>
            }
 <Link id="nav-dashboard" to="/health">
              <i className="fa fa-desktop color-blue2-dark" />
              <span>Dashboard
                <EditLocaleIco localeType="side_menu" labelObj={{key:'dashboard', label:'Dashboard'}}/>
              </span>
              <i className="fa fa-angle-right" />
            </Link>

*/