import React, { Fragment, useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import useStyle from '../../hooks/useStyle';
import { useHistory } from "react-router-dom";
import SubscriptionAccessesService from '../Subscriptions/SubscriptionAccessesService';
import CheckAccess from './CheckAccess';
import { DataSourcePermissionKey } from '../../constants/DataSourceList';
import FavouriteService from '../../services/FavouritesService';
import RoleService from '../Roles/RoleService.js';
import { useCurrentUserHook } from '../Authentication/useUserHook.js';
import { Spinner } from '../Common/Spinner.js';

const UserAccessComponents = (props) => {
  const {isOpen, toggleModal, subscription, isPopupMode} = props;
  useStyle("filter_dialog");
  
  const history = useHistory();
  
  const {currentUser} = useCurrentUserHook();

  const [components, setComponents] = useState({});
  const [favourites, setFavourites] = useState({});
  const [role, setRole] = useState();
  const [loading, setLoading] = useState(true);
  const [index, setIndex] = useState(null);
  const [orgMember, setOrgmember] = useState(props.orgMember);

  useEffect(() => {
    getUserAccess();
  }, [])

  const getUserAccess = async ()=>{
    setLoading(true);
    await getSubscriptionAccess();
    await getRole();
    setLoading(false);
  }

  /*useEffect(() => {
    SubscriptionAccessesService.getAll({
      "subscription_id": currentUser.current_subscription?.id,
      "role_ids": currentMember.role_record_id,
      "favourite_params":{
        "favorable_type": "journey_component"
      }
    }).then(({status, data})=>{
      if(status == 200){
        //setComponents(data.journey_components)
        setRolesDescription(data.roles?.join('\n'))
        setFavourites(data.favourites)
        //setIndex(Object.keys(data.journey_components)?.length - 1)
        setLoading(false)
      }
    })
  }, [])*/  

  const getSubscriptionAccess = async () =>{
    if(subscription){
      const {status, data} = await SubscriptionAccessesService.get(
        subscription.id, 
        {'locale':currentUser.current_locale}
      );
      if(status === 200){
        setComponents(data);
      }
    }
  }

  const getRole = async () =>{
    if(orgMember.permissions){
      setRole({role: orgMember.role_names});
    }else if(orgMember.role_record_id){
      const {status, data:{role}} = await RoleService.get(orgMember.role_record_id);
      if(status === 200){
        setOrgmember({...orgMember, permissions: role.permissions});
        setRole(role);
      }
    }
  }

  const addOrRemoveFavourite = (component, favourite) =>{
    setLoading(true);
    if(favourite?.id){
      FavouriteService.delete(favourite.id).then(({status, data})=>{
        if(status == 204)
          favourites[component.pk_id] = null;
          setLoading(false);
      })
    }else{
      const req = {
        'favorable_type':'journey_component', 
        'favorable_id':component.pk_id, 
      }
      FavouriteService.create(req).then(({status, data})=>{
        if(status == 201)
          favourites[component.pk_id] = data.favourite;
          setLoading(false);
      })
    }
  }

  const Favourite = ({component}) =>{
    const isFav = favourites[component.pk_id]?'fas fa-star':'far fa-star';
    return <i onClick={() => addOrRemoveFavourite(component, favourites[component.pk_id])} className={`${isFav} black pull-right`}/>;
  }

  const Components = ({list=[], dataSourceKey}) =>{
    return(
      <div className="popup-menu-grid br-grey" id={`dsl_${dataSourceKey}`}>
        {list.map((o, k) =>
          <div className="ver-icons" key={k}>
            <span><i className={o.icon || "fas fa-plus"} style={{ color: o.icon_color }}/></span>
            <div className="popup-menu-title">{o.label}</div>
            <Favourite component={o}/>
            <div className="m-l-40 black-light-1">{o.description || o.detail}</div>
          </div>
        )}
      </div>
    )
  }

  const Component = ({o, k}) => {
    const color = components[o]?.settings?.bg_color || '#5E5E5E';
    const style = {borderLeft: `6px solid ${color}`};
    const permissionKey = DataSourcePermissionKey[components[o].id];

    const accessList = useMemo(() => 
      components[o]?.options?.filter(o => CheckAccess.checkUserAccess(orgMember, orgMember?.permissions?.[permissionKey], o.sid)), []
    )

    if(accessList.length === 0) return null;
    
    return(
      <>
        <div className="flex justspacebetween aligncenter black p-6 font-15 br-grey m-b-10 m-t-10"  
          id={`ds_${o}`} onClick={e=> index === k ? setIndex(null) : setIndex(k)} style={style}>
            {components[o].label}
            <i className={`fas ${index === k ? 'fa-chevron-up' : 'fa-chevron-down'} black pull-right m-r-10`} onClick={()=>setIndex(index === k?null:k)}/>
        </div>
            
        {(index === k || index === "5") && <Components list={accessList} dataSourceKey={o}/>}
      </>
    )
  }

  const ComponentList = () => Object.keys(components).map((o, k) =>
    <Component o={o} key={k} k={k}/>  
  )
  
  const UserAccessComponents = () =>{
    return(
      <>
        {loading ?
          <Spinner/>
          :
          <div className={`p-10 ${isPopupMode ? 'scroll-x vh-90' : ''}`}>
            <div className="flex justspacebetween aligncenter">
              {role &&
                <h2 className="m-b-20">
                  {role.role}
                </h2>
              }
              <span className="checkbox-container" onClick={() => index === "5" ? setIndex(null) : setIndex("5")}>
                <span className="font-15 bold-500">View all</span>
                <input type="checkbox" checked={index === "5"} readOnly/>
                <span className="checkbox-checkmark"/>
              </span>
            </div>
            <ComponentList/>
          </div>
        }
      </>
    )
  }

  if(isPopupMode){
    return(
      ReactDOM.createPortal(
        <>
          <div className="ml-modal">
            <div className="ml-modal-content ml-card-4 ml-animate-zoom brd-10">
              
              <div className="flex justspacebetween modal-title">
                <h4 className="f18 text-white fw500 p12-10-15">Services available to access</h4>
                <button className="btn-close" title="Close Modal" onClick={e => toggleModal(e)}>
                  ×
                </button>
              </div>
              
              <UserAccessComponents/>
                
            </div>
          </div>
        </>, document.body
      )
    )
  }

  return <UserAccessComponents/>

}

export default UserAccessComponents;